import { defineStore } from 'pinia'
import { fetchData } from '~/utils/api'
import { endpoints } from '~/constants/endpoints'
import type { UserType } from '~/types/User'

export interface SocialLoginResponse {
    idToken: string | undefined
    provider: 'google' | 'facebook' | 'apple'
}

export const useAuthStore = defineStore('authStore', {
    state: () => ({
        loading: false,
        error: null as string | null,
    }),
    actions: {
        async loginWithSocial(request: SocialLoginResponse) {
            const userStore = useUserStore()

            await fetchData(endpoints.auth.social, {
                method: 'POST',
                body: request
            }).then(async ({data, meta}) => {
                console.log(data);
                
                if(data?.token){
                    userStore.setToken(data.token)
                }
                if(data?.user){
                    userStore.isAuthenticated = true
                    await nextTick()
                    await userStore.fetchUser()
                }
            }).catch((e) => {
                return Promise.reject(e);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}) 