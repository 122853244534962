<script lang="ts" setup>
import RegistrationForm from "~/components/layouts/authForms/RegistrationForm.vue";
import {Vue3Lottie} from "vue3-lottie";
import {fetchData} from "~/utils/api";
import {endpoints} from "~/constants/endpoints";
import {toValue} from "vue";
import {ErrorMessage} from "vee-validate";
import Error from "~/components/layouts/notice/Error.vue";
import InputSetPassword from "~/components/custom-ui/InputSetPassword.vue";
import {RoleEnum} from "~/types/User";

const props = defineProps({
    initialUserData: {
        type: Object,
        required: true,
    },
    type: {
        type: String,
        default: 'email',
    }
})

const {t} = useI18n()
const userStore = useUserStore()

const submitButton = ref()
const loading = ref(false)
const isVerified = ref(false);
const recordId = ref(null)
const title = computed(() => {
    if (!isVerified.value) return t('Enter Verification Code')
    return t('Set a new password')
})
const subtitle = computed(() => {
    const receiver = props.type === 'phone' ? props.initialUserData.phone : props.initialUserData.email
    if (!isVerified.value) return `${t('We’ve sent a code to')} ${receiver}!`;
    return t('Set up a secure password to protect your account.')
})
const reSendTimeLeft = ref(60)
const reSendOTPActive = computed(() => reSendTimeLeft.value === 0)

const startResendTimer = () => {
    reSendTimeLeft.value = 60
    const timer = setInterval(() => {
        reSendTimeLeft.value--
        if (reSendTimeLeft.value === 0) {
            clearInterval(timer)
        }
    }, 1000)
}

//$t('Wrong validation code. Try again.')

async function handleOTPSubmit(values, context) {
    loading.value = true
    const body = props.type === 'phone' ? {phone: props.initialUserData.phone} : {email: props.initialUserData.email}
    body.code = values.pin.join('')
    await fetchData(endpoints.auth.otp.verify, {
        method: "POST",
        body: body
    }).then(({data, meta}) => {
        isVerified.value = true
        recordId.value = data.recordId
    }).catch((error) => {
        context.setErrors({global: error.data.error})
        console.error(error.data.error)
    }).finally(() => {
        loading.value = false
    })
}

async function sendOTP() {
    const body = props.type === 'phone' ? {phone: props.initialUserData.phone} : {email: props.initialUserData.email}
    await fetchData(endpoints.auth.otp.send, {
        method: "POST",
        body: body
    }).then((response) => {
        startResendTimer()
    }).catch((error) => {
        console.error(error)
    })
}

onMounted(async () => {
    await sendOTP()
})

function handleComplete() {
    submitButton.value?.$el?.click()
}

async function handleRegisterSubmit(values, context) {
    loading.value = true
    const body = props.type === 'phone' ? {phone: props.initialUserData.phone} : {email: props.initialUserData.email}
    body.password = values.password
    body.recordId = recordId.value
    
    await userStore.setPassword(body)
        .then((response) => {
            navigateTo({
                name: userStore.user?.user_role === RoleEnum.MASTER ? 'master_office' : 'customer_office'
            })
        })
        .catch((error) => {
            context.setErrors({global: error.data.error})
        })
        .finally(() => {
            loading.value = false
        })
}
</script>

<template>
    <div>
        <UiDialogHeader>
            <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:mail-check-fill"/>
            <UiDialogTitle class="text-center">{{ title }}</UiDialogTitle>
            <UiDialogDescription class="text-gray-500 border-b-gray-300 border-b pb-5 text-center">{{
                    subtitle
                }}
            </UiDialogDescription>
        </UiDialogHeader>

        <UiForm v-if="isVerified" ref="OTPForm" @submit="handleRegisterSubmit" class="grid gap-6 mt-6">
            <div class="">
                <InputSetPassword name="password" :placeholder="$t('Password')" :confirm-placeholder="$t('Password')" />
            </div>
            
            <ErrorMessage
                    as="p"
                    name="global"
                    v-slot="{ message }"
                    class="text-sm font-medium text-red-50top-auto left-auto"
            >
                <Error :message="message"/>
            </ErrorMessage>
            <UiButton
                    :disabled="loading"
                    class="ys-button w-full"
            >
                {{ $t("Create Account") }}
            </UiButton>
        </UiForm>

        <UiForm v-else ref="OTPForm" @submit="handleOTPSubmit" class="grid gap-6 mt-6">
            <CustomUiInputPin @complete="handleComplete"/>
            <ErrorMessage
                    as="p"
                    name="global"
                    v-slot="{ message }"
                    class="text-sm font-medium text-red-500 top-auto left-auto"
            >
                <Error :message="message"/>
            </ErrorMessage>
            <UiButton ref="submitButton"
                      :disabled="loading"
                      class="ys-button w-full"
            >
                {{ $t("Submit Code") }}
            </UiButton>
            <div class="text-center">
                <div class="text-sm text-slate-50043 mb-1">{{ $t("Experiencing issues receiving the code?") }}</div>
                <UiButton v-if="reSendOTPActive" class="text-sm" variant="link" @click="sendOTP">{{
                        $t("Resend code")
                    }}
                </UiButton>
                <client-only v-else>
                    <Vue3Lottie
                            :height="30"
                            :width="30"
                            animationLink='/Loader/loader-p.json'
                    />
                </client-only>
            </div>
        </UiForm>
        

<!--        <RegistrationForm v-else/>-->
    </div>
</template>