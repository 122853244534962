<script lang="ts" setup>
import Warning from "~/components/layouts/notice/Warning.vue";
import {ErrorMessage, useForm, Form, type FormContext} from "vee-validate";
import Error from "~/components/layouts/notice/Error.vue";
import {fetchData} from "~/utils/api";
import {endpoints} from "~/constants/endpoints";
import {RoleEnum} from "~/types/User";
import {useNuxtApp} from "#app";
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
    signInWithPopup,
    type Auth
} from "firebase/auth";
import {useAuthStore} from "~/stores/auth";
import type {SocialLoginResponse} from "~/stores/auth";

const {t} = useI18n()
const emit = defineEmits(['toRegistration'])
const userStore = useUserStore()
const {$auth, $googleProvider, $facebookProvider, $appleProvider} = useNuxtApp()

const type = ref('email')
const flow = ref('login')
const loading = ref(false)
const isUserExists = ref(false)
const isUserChecked = ref(false)
const initialUserData = ref({
    email: null,
    phone: null
})

const title = computed(() => {
    if (!isUserChecked.value) return t('Login or register')
    if (!isUserExists.value) return t('Create a new account')
    return t('Login')
})
const subtitle = computed(() => {
    if (!isUserChecked.value) return t('Enter your details to login')
    if (!isUserExists.value) return t(`Join us by ${type.value}`)
    return t('Enter your details to login')
})

const statesStore = useStatesStore();

interface FormValues {
    email?: string;
    phone?: string;
    password?: string;
}

const authStore = useAuthStore()

async function onSubmit(values: FormValues, context: FormContext) {
    console.log('handleSubmit')
    loading.value = true
    if (!isUserChecked.value) {
        initialUserData.value = values
        if (values.phone) {
            values.phone = values.phone.replace(/[^\d]/g, '')
        }
        fetchData(endpoints.auth.check, {
            method: "GET",
            query: values
        }).then(({data, meta}) => {
            if (data?.user) {
                isUserExists.value = true
            } else {
                context.setErrors({global: t('The user with this email does not exist!')})
            }
        }).catch((error) => {
            context.setErrors({global: error.data.errors[0]})
        }).finally(() => {
            isUserChecked.value = true
            loading.value = false
        })
        return
    } else {
        userStore.login(values)
            .then((response) => {
                navigateTo({
                    name: userStore.user?.user_role === RoleEnum.MASTER ? 'master_office' : 'customer_office'
                })
            })
            .catch((error) => {
                console.error(error.data)
                context.setErrors({global: error.data?.errors[0]})
            })
            .finally(() => {
                isUserChecked.value = true
                loading.value = false
            })
    }
}

function toRegistration() {
    emit('toRegistration', {
        initialUserData: initialUserData.value,
        type: type.value
    })
}

function ForgotPassword() {
    statesStore.OTPIsVerify = false;
    statesStore.UserForgotPassword = true;
}

const resetPasswordData = ref({
    type: type.value,
    phone: null,
    email: null
})

const loginForm = ref<FormContext | null>(null)

function toResetPassword(){
    const formValues = loginForm.value?.values
    resetPasswordData.value.type = type.value
    resetPasswordData.value.phone = formValues?.phone
    resetPasswordData.value.email = formValues?.email
    flow.value = 'reset_password'
}

function handleBackFromSetPassword(){
    flow.value = 'login'
    console.log(type.value, type.value === 'phone' ? resetPasswordData.value.phone : resetPasswordData.value.email)
    loginForm.value?.setFieldValue(type.value, type.value === 'phone' ? resetPasswordData.value.phone : resetPasswordData.value.email)
}

async function signInWithGoogle() {
    try {
        loading.value = true
        const result = await signInWithPopup($auth as Auth, $googleProvider as any)
        await authStore.loginWithSocial({
            idToken: result.user.accessToken,
            provider: 'google'
        })
        navigateTo({
            name: userStore.user?.user_role === RoleEnum.MASTER ? 'master_office' : 'customer_office'
        })
    } catch (error) {
        console.error('Google sign in error:', error)
    } finally {
        loading.value = false
    }
}

async function signInWithFacebook() {
    try {
        loading.value = true
        const result = await signInWithPopup($auth as Auth, $facebookProvider as any)
        //const credential = FacebookAuthProvider.credentialFromResult(result)
        await authStore.loginWithSocial({
            idToken: result.user.accessToken,
            provider: 'facebook'
        })
        navigateTo({
            name: userStore.user?.user_role === RoleEnum.MASTER ? 'master_office' : 'customer_office'
        })
    } catch (error) {
        console.error('Facebook sign in error:', error)
    } finally {
        loading.value = false
    }
}

async function signInWithApple() {
    try {
        loading.value = true
        const result = await signInWithPopup($auth as Auth, $appleProvider as any)
        await authStore.loginWithSocial({
            idToken: result.user.accessToken,
            provider: 'apple'
        })
        navigateTo({
            name: userStore.user?.user_role === RoleEnum.MASTER ? 'master_office' : 'customer_office'
        })
    } catch (error) {
        console.error('Apple sign in error:', error)
    } finally {
        loading.value = false
    }
}
</script>
<template>
    <div class="">
        <template v-if="flow === 'login'">
            <UiDialogHeader>
                <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:user-fill"/>
                <UiDialogTitle class="text-center">{{ title }}</UiDialogTitle>
                <UiDialogDescription class="text-center text-gray-500 border-b-gray-300 border-b pb-5">
                    {{ subtitle }}
                </UiDialogDescription>
            </UiDialogHeader>
            <UiForm class="grid gap-4 mt-6" @submit="onSubmit" v-slot="{errors, values, meta}" :key="type" ref="loginForm">
                <CustomUiInputEmail v-if="type === 'email'" :disabled="isUserChecked" placeholder="hello@yourservice.com" />
                <CustomUiInputPhone v-if="type === 'phone'" :disabled="isUserChecked" />
                <CustomUiInputPassword v-if="isUserExists" />
                <template v-if="isUserChecked && !isUserExists">
                    <Warning :message="$t(`The user with this ${type} does not exist!`)"/>
                    <UiButton class="ys-button w-full" type="button" @click.prevent="toRegistration">
                        <span>{{ $t("Create a new account") }}</span>
                    </UiButton>
                </template>
                <template v-else>
                    <div v-if="isUserExists" class="flex justify-between">
                        <div class="flex items-center space-x-2">
                            <UiCheckbox id="keep_logged" class="rounded"/>
                            <label class="text-sm font-regular" for="keep_logged">
                                {{ $t("Keep me logged in") }}
                            </label>
                        </div>
                        <button type="button" class="underline text-xs text-end p-0"
                                @click.prevent="toResetPassword">
                            {{ $t("Forgot password?") }}
                        </button>
                    </div>

                    <ErrorMessage
                            v-slot="{ message }"
                            as="p"
                            class="text-sm font-medium text-red-500 top-auto left-auto"
                            name="global"
                    >
                        <Error :message="message"/>
                    </ErrorMessage>

                    <UiButton
                            :disabled="loading"
                            variant="primary"
                            class="rounded-xl w-full"
                            type="submit"
                    >
                        <Icon v-if="loading" name="svg-spinners:ring-resize"/>
                        <span v-else>{{ isUserExists ? $t("Login") : $t('Next') }}</span>
                    </UiButton>
                </template>
            </UiForm>

            <div class="text-center mt-4">
                <div class="text-sm text-gray-500 mb-4">
                    {{ $t("Or continue with") }}
                </div>
                <div class="flex gap-4 justify-center">
                    <UiButton
                        variant="outline"
                        class="flex items-center gap-2"
                        @click="signInWithGoogle"
                        :disabled="loading"
                    >
                        <Icon name="logos:google-icon" />
                        Google
                    </UiButton>
                    <UiButton
                        variant="outline"
                        class="flex items-center gap-2"
                        @click="signInWithFacebook"
                        :disabled="loading"
                    >
                        <Icon name="logos:facebook" />
                        Facebook
                    </UiButton>
                    <UiButton
                        variant="outline"
                        class="flex items-center gap-2"
                        @click="signInWithApple"
                        :disabled="loading"
                    >
                        <Icon name="logos:apple" />
                        Apple
                    </UiButton>
                </div>
            </div>

            <div class="text-center mt-4">
                <div class="text-sm text-gray-500">
                    {{ $t("Other methods") }}
                </div>
                <UiButton v-if="type === 'email'" class="text-gray-950 underline hover:text-primary-500 transition-all" variant="link"
                          @click.prevent="type = 'phone'">
                    {{ $t("Sign in with your phone number") }}
                </UiButton>
                <UiButton v-if="type === 'phone'" class="text-gray-950 underline hover:text-primary-500 transition-all" variant="link"
                          @click.prevent="type = 'email'">
                    {{ $t("Sign in with your email") }}
                </UiButton>
            </div>
        </template>
        <template v-if="flow === 'reset_password'">
            <LayoutsAuthFormsResetPassword @back="handleBackFromSetPassword" :type="resetPasswordData.type" :phone="resetPasswordData.phone" :email="resetPasswordData.email" />
        </template>
    </div>
</template>